@media all and (max-width:1141px) {
  .lower-footer {
    .content {
      padding-left: 20px;
      padding-right: 20px;
      flex-wrap: wrap;
    }
  }

  .upper-footer {
    .content {
      padding-left: 20px;
      padding-right: 20px;
      flex-wrap: wrap;
    }
  }
}