@import "~bootstrap/scss/bootstrap";
@import "https://site-assets.fontawesome.com/releases/v6.1.1/css/all.css"; 
@import "https://cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick.css";
@import "https://cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick-theme.css";  
@import "animate.min.css";
@import 'app.css';  
@import 'header.scss';
@import 'navigation.scss'; 
@import 'footer.scss';
@import 'custom.scss';
@import 'responsive-footer.scss';
@import 'mobile-navigation.scss'; 
@import 'responsive.scss';
@import 'imprint.scss';