@media (max-width: 991px) {
  .persönlich {
    .top {
      .btn-lg {
        min-width: 185px;
        font-size: 17px;
      }
    }
  }
  .taggeld {
    .row {
      gap: 15px;

      div[class*="col-"] {
        flex: 0 0 calc(33.33333333% - 10px);
        max-width: calc(33.33333333% - 10px);
      }
    }

    .taggeld_box {
      min-height: 240px;

      h4 {
        font-size: 18px;
      }
    }
  }
}

@media (max-width: 767px) {
  .home_banner {
    .banner_box {
      .left-box {
        padding-bottom: 30px;

        h2 {
          font-size: 30px;
        }

        h3 {
          font-size: 22px;
        }

        .btn_group {
          position: static;
          margin-top: 20px;

          .btn-lg {
            min-width: 178px;
            font-size: 17px;
            margin-bottom: 10px;
          }

          .btn-dark {
            margin-left: 10px;
          }
        }
      }
    }
  }
  .slider.center {
    .slick-list {
      padding: 45px 0 !important;

      .item.slick-current {
        .customer_box {
          margin: 0px;
          box-shadow: 0px 0px 10px 0px rgba(95, 96, 96, 0.7);
          width: 100%;
          height: 100%;

          &::after {
            top: 4px;
            bottom: 4px;
            left: 4px;
            right: 4px;
          }
        }
      }
    }
  }
  .zahlen {
    padding-top: 100px;

    .title_h3 {
      margin-bottom: 100px;
    }

    div[class*="col-"] {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .zahlen_box {
      margin-bottom: 20px;
    }

    .zahlen_bx {
      .left, .right {
        width: 100%;
      }

      .right {
        padding: 20px;
      }
    }
  }
  .kontaktieren {
    padding-bottom: 140px;
    padding-top: 140px;
    background-size: cover;

    div[class*="col-"] {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .kontaktieren_form {
      margin-top: 20px;
    }
  }

  .taggeldinside {
    .row {
      div[class*="col-"] {
        flex: 0 0 100%;
        max-width: 100%;

        .taggeld_box {
          margin-bottom: 20px;

          &:after, &:before {
            display: none;
          }
        }
      }
    }
  }
  .taggeld_grid_box {
    padding: 47px;

    img {
      position: static;
      margin-bottom: 16px;
    }
  }
  .kmu {
    .banner {
      padding-top: 55px;
      min-height: 530px;

      h3 {
        font-size: 28px;
        margin-bottom: 40px;
      }

      .btn-outline {
        margin-left: 10px;

        .btn {
          min-height: 71px;
          min-width: 258px;
          font-size: 16px;
        }
      }
    }
  }
  .kmu-new {
    .persönlich {
      margin-top: 50px;

      .top {
        p {
          font-size: 20px;
        }

        h4 {
          font-size: 25px;
        }
      }

      .cemter_part {
        .title_h3 {
          text-align: center;
          font-size: 24px;
        }

        .cemter_part_box {
          padding-left: 0px;

          p {
            font-size: 20px;
          }

          h1 {
            position: static;
          }
        }
      }

      .bottom_part {
        margin-top: 65px;
        padding: 25px;
      }

    }
  }
  .privatpersonen_section {
    .row {
      div[class*="col-"] {
        flex: 0 0 100%;
        max-width: 100%;
      }

      .pe-4 {
        padding-right: 15px !important;
      }

      .ps-4 {
        padding-left: 15px !important;
      }
    }
  }
  .privatpersonen-banner-box {
    max-width: 100%;
  }
  .privatpersonen_section {
    padding-top: 115px;
    padding-bottom: 30px;
    .taggeld_grid_box {
      margin-bottom: 20px;
      height: auto;
    }
  }
  .privatpersonen-banner {
    h4 {
      font-size: 28px;
    }
  }
  .privatpersonen {
    .kontaktieren {
      padding-top: 110px;
    }
  }

}

@media (max-width: 507px) {
  .home_banner {
    .banner_box {
      .left-box {
        .btn_group {
          .btn.btn-dark {
            margin-left: 0px;
          }
        }
      }
    }
  }
}