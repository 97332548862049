.nav-item {
  text-transform: uppercase;
}
.navbar-custom-nav{  
  position: fixed;
  top: 0;
  left: 0;  
  right: 0;
  padding-top: 30px;
  padding-bottom: 30px;z-index: 99;   
  &.fixed {
    background-color: rgba(0,0,0,0.7);  
  }
  #navbarSupportedContent{
    z-index:10;
  .navbar-nav{      
      display: flex;
      li {
        list-style-type: none;
        margin-right:2px;
        a {
          text-decoration: none;
          color:#fff;
          padding: 8px 20px 9px 20px; 
          display: block;
          font-weight: bold;
          border-radius: 4px;
          font-size: 16px;
          transition: all 0.2s;
        }
        .active {
          background-color: #3550a0;
        }
        a:hover {
          background-color: #3550a0;
        }
        .dropdown-menu{
            li{
              a{color: #444;
                &:hover{color: #FFF;}   
              } 
            }
        }
      }
    }
  }  
}  
  

@media ( max-width:992px ) {
  .navbar-custom-nav{
    .navbar-brand{
      > img{max-width: 350px;} 
    }
  }
}
@media ( max-width:991px ) {
  .navbar-light {
    .navbar-toggler{border-color: rgb(255, 255, 255);background-color: #FFF;}
  } 
  .navbar-custom-nav {
    #navbarSupportedContent {   
      background-color: rgba(255, 255, 255, 0.99);
      position: absolute;
      top: 98px;  
      left: 0;
      right: 0;
      padding: 20px;
      min-height: 340px;
      box-shadow: 0 8px 8px rgba(0,0,0,0.4);
      .navbar-nav{
        li {
          a{border-bottom: 1px solid #3550a03b;color: #3550a0;border-radius: 0;padding: 12px;
            &:hover{color: #FFF;}  
          }  
          &:last-child{
            a{border-bottom: none;} 
          } 
          .dropdown-menu {
            position: static;
            border: none;
            background-color: transparent;
            margin: 0;
            padding: 0 15px; 
            li{
              a{color: #444;font-size: 14px;font-weight: 600;}
              &:last-child{
                a{border-bottom: none;}
              }   
            }
          } 
        }
      }
    }  
  }
} 
 

@media ( max-width:767px ) {     
  .navbar-custom-nav {
    .navbar-brand {
      > img {
        max-width: 220px; 
      }
    }
  }
}