#hamburger {
  display: none;
  top:60px;
  right:30px;
}

.mobile-burger {
  background:#fff; width: 55px; height: 50px;
  position: absolute;
  top:32px;
  right:32px;
  border-radius: 4px;
  transition: border-radius .5s;
  z-index:50;
  .line {
    position: absolute;
    left:10px;
    height: 4px; width: 35px;
    background: #3550a0; border-radius: 2px;
    display: block;
    transition: 0.5s;
    transform-origin: center;
  }

  .line:nth-child(1) { top: 12px; }
  .line:nth-child(2) { top: 24px; }
  .line:nth-child(3) { top: 36px; }


}

.mobile-burger.checked .line:nth-child(1){
  transform: translateY(12px) rotate(-45deg);
}

.mobile-burger.checked .line:nth-child(2){
  opacity:0;
}

.mobile-burger.checked .line:nth-child(3){
  transform: translateY(-12px) rotate(45deg);
}
@media all and (max-width:1200px) {
  #hamburger {
    display: block;
  }
  #navigation {
    display: none;
  }
  #navigation ul {
    flex-direction: column;
    position: absolute;
    right: 40px;
    top: 78px;
  }
}