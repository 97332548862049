@media (min-width: 1400px) {
  .container {
    max-width: 1165px;
  }
}

body, html {
  font-family: 'Open Sans', sans-serif !important;
  overflow-x: hidden;
}

p {
  font-size: 16px;
}

.btn-white {
  background-color: #FFF;
  color: #3550a0;
}

.btn-lg {
  font-size: 22px;
  font-weight: bold;
  min-height: 70px;
  min-width: 315px;
}

.fa-rotate-50 {
  -webkit-transform: rotate(50deg);
  transform: rotate(-50deg);
}

section {
  float: left;
  width: 100%;
}

.home_banner {
  min-height: 900px;

  .banner_box {
    position: relative;
    float: left;

    .left-box {
      max-width: 701px;
      background-color: #3550a0;
      padding: 30px 30px 62px 30px;
      float: left;
      border-radius: 10px;
      width: 100%;
      position: relative;
      z-index: 9;
      box-shadow: 40px 40px 50px 8px rgba(0, 0, 0, 0.5);

      h2 {
        font-size: 60px;
        color: #FFF;
        font-weight: bold;
        margin: 0px;
      }

      h3 {
        font-size: 40px;
        color: #FFF;
        font-weight: bold;
        margin: 15px 0 22px 0;
      }

      p {
        font-size: 22px;
        color: #FFF;
      }

      .btn_group {
        float: left;
        position: absolute;
        bottom: -40px;

        .btn {
          i {
            margin-right: 15px;
          }

          &.btn-dark {
            margin-left: 20px;
            min-width: 256px;
          }
        }
      }
    }

    .blue-box-outerline {
      border: 2px solid #FFF;
      position: absolute;
      top: 15px;
      bottom: -15px;
      left: 15px;
      right: -15px;
      border-radius: 10px;
      z-index: 0;
    }
  }
}

.footer-wrapper {
  float: left;
  width: 100%;
}

.customer_box {
  float: left;
  width: 100%;
  padding: 30px;
  text-align: center;
  box-shadow: 0px 0px 30px 0px rgba(95, 96, 96, 0.13);
  border-radius: 15px;

  .customer_photo {
    width: 138px;
    height: 138px;
    object-fit: cover;
    border-radius: 100%;
    margin-bottom: 20px;
    display: inline-block;
  }

  h3 {
    font-size: 18px;
    color: #3550a0;
    font-weight: bold;
    margin: 0px;
  }

  p {
    color: #6d7276;
  }
}

ul.star_list {
  margin: 0px;
  list-style: none;
  padding: 0px;

  li {
    display: inline-block;

    i {
      font-size: 23px;
      color: #e8cf5d;
    }
  }
}


.slider.center {
  .slick-list {
    padding: 95px 0 !important;

    .item {
      margin: 10px 25px;
      position: relative;

      &.slick-current {
        // transform: scale(1.2);
        z-index: 9;

        .customer_box {
          margin: -15px -50px -15px -50px;
          width: calc(100% + 100px);
          border: 4px solid transparent;
          background-color: #f6f7f8;
          height: calc(100% + 60px);
          padding-top: 60px;
          padding-bottom: 65px;
          box-shadow: 0px 0px 80px 0px rgba(95, 96, 96, 0.7);

          &::after {
            content: "";
            border: 3px solid #3550a0;
            position: absolute;
            top: -25px;
            bottom: -25px;
            left: -60px;
            right: -60px;
            border-radius: 15px;
          }
        }
      }

      ul.star_list {
        margin: 15px 0 18px 0;
      }
    }
  }

  .slick-prev {
    left: calc(50% - 40px);
    top: auto;
    bottom: 0px;
    z-index: 9;

    &::before {
      content: "\f177";
      font-family: "Font Awesome 6 Pro";
      font-weight: 900;
      color: #3550a0;
      font-size: 35px;
      opacity: 1;
    }
  }

  .slick-next {
    top: auto;
    bottom: 0px;
    right: calc(50% - 40px);
    z-index: 9;

    &::before {
      content: "\f178";
      font-family: "Font Awesome 6 Pro";
      font-weight: 900;
      color: #3550a0;
      font-size: 35px;
      opacity: 1;
    }
  }

}

.title_h3 {
  font-size: 33px;
  color: #3550a0;
  font-weight: bold;
  position: relative;
  text-transform: uppercase;

  span {
    position: relative;
    padding-bottom: 45px;

    &::before {
      content: "";
      border-bottom: 3px solid #3550a0;
      position: absolute;
      bottom: 0;
      right: 50px;
      left: 50px;
    }
  }
}


.zahlen_bx {
  float: left;
  width: 100%;
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 90px 0px rgba(0, 0, 0, 0.8);
  overflow: hidden;

  .left {
    width: 360px;
    float: left;

    img {
      width: 100%;
      object-fit: cover;
      border-radius: 10px 0px 0 10px;
      min-height: 320px;
    }
  }

  .right {
    float: right;
    width: calc(100% - 360px);
    padding: 42px 100px 45px 50px;

    h4 {
      font-size: 30px;
      font-weight: bold;
      color: #3550a0;
      margin-bottom: 20px;
    }

    p {
      font-size: 20px;
      color: #000000;
    }

    .big_link {
      margin-top: 10px;
    }

  }
}

.zahlen {
  background-image: url(../images//bg-img-1.jpg);
  background-size: cover;
  padding-top: 150px;
  padding-bottom:190px;
  .title_h3 {
    color: #FFF;
    text-align: center;
    margin-bottom: 200px;

    span {
      &::before {
        border-bottom-color: #FFF;
      }
    }
  }

  .zahlen_bx {
    margin-top: 26px;
    margin-bottom: -146px;
    position: relative;
    z-index: 9;
  }
}

.zahlen_box {
  min-height: 324px;
  display: inline-flex;
  align-items: center;
  float: left;
  width: 100%;
  border-radius: 10px;
  background-color: rgba(38, 59, 122, 0.8);
  box-shadow: 0px 0px 80px 0px rgba(0, 0, 0, 0.6);
  text-align: center;
  flex-wrap: wrap;
  justify-content: center;
  line-height: normal;
  flex-direction: column;
  margin-bottom:20px;

  .stat-number {
    font-size: 85px;
    color: #ffffff;
    font-weight: bold;
    line-height: normal;
  }

  .stat-title {
    font-size: 30px;
    font-weight: 600;
    color: #ffffff;
    text-transform: uppercase;
    line-height: 37px;
  }
}


.big_link {
  font-size: 20px;
  color: #000000;
  font-weight: bold;
  text-decoration: none;
  display: inline-flex;
  align-items: center;

  i {
    color: #3550a0;
    font-size: 30px;
    margin-left: 15px;
  }
}

.kontaktieren {
  background-image: url(../images/bg-img-2.png);
  background-repeat: no-repeat;
  background-position: right center;
  padding-bottom: 140px;
  padding-top: 140px;

  .title_h3 {
    margin-bottom: 48px;

    span {
      padding-bottom: 48px;
      display: inline-block;

      &::before {
        left: 0px;
      }
    }
  }

  P {
    font-size: 16px;
    color: #595d60;
  }

  .form-control {
    height: 65px;
    background-color: transparent;
  }

  .btn-primary {
    min-width: 220px;
    font-size: 17px;
    margin-top: 20px;
    min-height: 65px;
  }
}

.kontaktieren_form {
  background-color: #3550a0;
  padding: 20px;
  border-radius: 10px;
  color: #ffffff;
  a {
    color: #ffffff;
  }
 .redBorder {
  border:3px solid #f00;
}
  label {
    display:none;
  }

  .form-control {
    margin-bottom: 20px;
    font-size: 17px;
    color: #ffffff;
    padding-left: 20px;

    &::placeholder {
      color: #ffffff;
    }
  }

  textarea.form-control {
    min-height: 100px;
  }

  .btn-lg {
    font-size: 17px;
    color: #3550a0;
    min-width: 180px;
    display: inline-flex;
    align-items: center;
    text-align: center;
    justify-content: center;

    i {
      font-size: 26px;
      margin-right: 10px;
    }
  }
}

.btn-primary {
  background-color: #3550a0;
  border-color: #3550a0;
}

.footer-wrapper {
  .footer-item {
    ul.footer-nav {
      padding: 0px;
    }

    .flex-upper-contact {
      a {
        color: #6d7276;
        text-decoration: none
      }
    }
  }
}

.header-1 {
  .logo-white {
    display: none;
  }

  .logo-black {
    display: inline-block !important;
  }

  .navbar-custom-nav {
    position: static;

    #navbarSupportedContent {
      .navbar-nav {
        li {
          a {
            color: #343434;

            &:hover, &.active {
              color: #FFF;
            }
          }
        }
      }
    }
  }
}

.kmu {
  .banner {
    background-image: url(../images/bg-img-2.jpg);
    background-repeat: no-repeat;
    padding-top: 165px;
    min-height: 840px;

    h3 {
      font-size: 60px;
      color: #000000;
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: 80px;

      span {
        color: #3550a0;
      }
    }

    .btn {
      min-height: 101px;
      min-width: 338px;
      max-width: 100%;
    }
  }

  .kontaktieren {
    padding-top: 120px;
    margin-top: 80px;
  }
}

.btn-outline {
  float: left;
  position: relative;

  &:after {
    content: "";
    border: 2px solid #000;
    position: absolute;
    top: 9px;
    bottom: -9px;
    left: -9px;
    right: 15px;
    border-radius: 5px;
    z-index: 0;
  }

  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9;
    position: relative;
    min-height: 100px;
    min-width: 330px;
  }
}

.taggeld_box {
  background-color: #3550a0;
  border-radius: 5px;
  min-height: 320px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  z-index: 9;
  position: relative;

  h4 {
    font-size: 26px;
    color: #ffffff;
    font-weight: bold;
    margin-top: 30px;
  }
}

.taggeld {
  .row {
    gap: 70px;

    div[class*="col-"] {
      flex: 0 0 calc(33.33333333% - 47px);
      max-width: calc(33.33333333% - 47px);
    }
  }
}

.taggeldinside {
  position: relative;
  margin-top: -160px;

  &::after {
    content: "";
    position: absolute;
    left: 30px;
    right: 30px;
    top: calc(50% - 6px);
    border-top: 14px solid #3550a0;
    z-index: 0;
  }

  .taggeld_box {
    &::after {
      content: "";
      width: 31px;
      height: 31px;
      border-radius: 3px;
      background-color: rgb(255, 255, 255);
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.7);
      position: absolute;
      right: -15.5px;
    }

    &::before {
      content: "";
      width: 31px;
      height: 31px;
      border-radius: 3px;
      background-color: rgb(255, 255, 255);
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.7);
      position: absolute;
      left: -15.5px;
    }
  }

  div[class*="col-"] {
    &:first-child {
      .taggeld_box {
        &:before {
          display: none;
        }
      }
    }

    &:last-child {
      .taggeld_box {
        &:after {
          display: none;
        }
      }
    }

  }
}


.taggeld_grid {
  margin-top: 46px;
}

.taggeld_grid_box {
  position: relative;
  border-radius: 5px;
  background-color: rgb(255, 255, 255);
  margin-bottom: 45px;
  box-shadow: 0px 0px 100px 0px rgba(0, 0, 0, 0.5);
  padding: 47px 47px 47px 165px;

  h4 {
    font-size: 20px;
    color: #6d7276;
    font-weight: bold;
    margin-bottom: 20px;
  }

  p {
    font-size: 16px;
    color: #6d7276;
    line-height: 24px;
  }

  img {
    position: absolute;
    top: 50px;
    left: 60px;
  }

  &:after {
    content: "";
    border: 4px solid #3550a0;
    position: absolute;
    top: 20px;
    right: 20px;
    bottom: -15px;
    left: 20px;
    border-radius: 5px;
  }
}


.kmu-new {
  .banner {
    background-image: url(../images/bg-img-3.jpg);
    background-size: cover;
    background-position: center;
  }

  .persönlich {
    margin-top: 150px;

    .top {
      h4 {
        font-size: 33px;
        color: #000000;
        font-weight: bold;
        padding-bottom: 8px;
      }

      p {
        font-size: 25px;
        color: #3550a0;
        font-weight: bold;
      }
    }

    .cemter_part {
      padding-top: 120px;

      .title_h3 {
        text-align: center;
        font-size: 34px;
        color: #3550a0;
        font-weight: bold;
        text-transform: none;
        margin-bottom: 60px;
      }

      .cemter_part_box {
        position: relative;
        padding-left: 80px;
        min-height: 145px;

        h1 {
          position: absolute;
          bottom: -45px;
          left: 20px;
          margin: 0;
          font-size: 95px;
          color: #3550a0;
          font-weight: bold;
        }

        p {
          font-size: 25px;
          color: #000000;
        }
      }
    }

    .bottom_part {
      float: left;
      background-color: #3550a0;
      font-size: 23px;
      color: #FFF;
      width: 100%;
      padding: 25px 25px 25px 46px;
      border-radius: 5px;
      position: relative;
      margin-top: 125px;
      margin-bottom: -65px;

      &::after {
        content: "";
        border: 2px solid #3550a0;
        position: absolute;
        top: 20px;
        left: -10px;
        bottom: -10px;
        right: -10px;
        border-radius: 5px;
      }
    }
  }

  .kontaktieren {
    margin-top: 0px;
    padding-top: 140px;
  }
}

.privatpersonen-banner {
  background-image: url(../images/bg-img-4.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 90px;
  padding-bottom: 100px;

  h4 {
    font-size: 40px;
    font-weight: bold;
    color: #ffffff;
    text-transform: uppercase;
  }
}

.privatpersonen-banner-box {
  background-color: rgba(38, 59, 122, 0.8);
  box-shadow: 0px 0px 80px 0px rgba(0, 0, 0, 0.6);
  padding: 40px;
  max-width: 65%;
  border-radius: 5px;
  margin-top: 46px;

  h5 {
    font-size: 16px;
    color: #ffffff;
    font-weight: bold;
    margin-top: 19px;
    margin-bottom: 15px;
  }

  p {
    font-size: 16px;
    color: #ffffff;
  }

  h3 {
    font-size: 20px;
    font-weight: bold;
    color: #FFF;
    margin-top: 15px;
    display: inline-block;
    margin-bottom: 30px;
  }
}
.btn{
  img{
    max-width: 25px;
  }
}
.privatpersonen_section {
  background-color: #f8f9fa;
  padding-top: 175px;
  padding-bottom: 160px;
  .fadeInLeft{
    img{
      max-height: 71px;
      margin-left: 45px;
    }
  }
  .fadeInRight{
    img{
      margin-left: 10px;
    }
  }
  .taggeld_grid_box {
    padding: 35px;
    height: 100%;
    box-shadow: 0px 0px 160px 0px rgba(0, 0, 0, 0.2);
    margin-bottom: 0px;

    > img {
      position: static;
    }

    &:after {
      display: none;
    }

    h4 {
      font-size: 16px;
      color: #000000;
      margin-top: 21px;
    }

    &.active {
      background-color: #3550a0;

      h4 {
        color: #FFF;
      }

      p {
        color: #FFF;
      }
    }
  }
}

.kmu-new {
  .persönlich {
    .bottom_part {
      margin-bottom: -35px;
    }
  }
}
