@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap');


html, body {
    margin:0;
    padding:0;
    font-family: 'Open Sans', sans-serif;
}

ul, li {
    margin:0;
    padding:0;
}

body {
    background-color: #fff;
}

.max-width {
    max-width: 1141px;
    width:100%;
    margin:auto;
}

a {
    text-decoration: none;
    color:#6d7276;
    transition: all 0.3s;
}

a:hover {
    color:#3550a0;
}