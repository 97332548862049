.lower-footer {
  .content {
    display: flex;
    justify-content: space-between;
    color:#fff;
    padding-top:21px;
  }
  background-color: #3550a0;
  min-height:60px;
  a {
    color:#fff;
    text-decoration: none;
    font-size: 16px;
    transition: all 0.2s;
    border-radius: 4px;
  }
  a:hover {
    background-color: #fff;
    color:#3550a0;
  }
}

.upper-footer {
  .content {
    display: flex;
    justify-content: space-between;
    color:#fff;
    padding-top:70px;
    .footer-item {
      .footer-item-header {
        font-size: 18px;
        font-weight: 600;
        color:#45494c;
        line-height: 28px;
        text-transform: uppercase;
      }
      .footer-item-content {
        color:#6d7276;
        line-height: 28px;
        padding-top:17px;
        .footer-nav {
          li {
            list-style-type: none;
            a {
              color:#6d7276;
              font-size: 16px;
              text-decoration: none;
              transition: all 0.2s;
              border-radius: 4px;
            }
            a:hover {
              color:#3550a0;
            }
          }
        }
        .flex-upper-contact {
          display: flex;
          gap:12px;
          color:#6d7276;
          line-height: 25px;
        }
        .footer-social {
          display: flex;
          gap:9px;
          padding-top:18px;
        }
      }
    }
  }
  background-color: #f8f9fa;
  min-height:335px;

}